@import '../../variables';

.Uusimmat {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 140px;

  .UusimmatColText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    text-align: center;

    p {
      margin: 0 20% 8px 20%;
    }
  }

  .scrollMenu {
    width: 90%;
    margin: auto;

    button:focus {
      outline: none !important;
    }

    a {
      display: flex;
      flex-direction: column;
      margin: auto;
      align-items: center;

      .menu-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        margin: 15px;
        height: 346px;
        max-width: 300px;
        cursor: pointer;
        box-shadow: $regularShadow;

        img {
          display: flex;
          align-self: flex-start;
          height: 200px;
          margin: 0 auto;
          width: 300px;
        }

        .uusimmat-textContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          padding: 0px;
          box-sizing: border-box;
          margin: 0 auto;
          background-color: #fff;
        }

        .uusimmat-textContainer h3 {
          font-size: 12px;
          font-weight: 400;
          color: #555555;
          margin: 10px auto 0 auto;
        }

        .uusimmat-textContainer h2 {
          font-size: 14px;
          margin: 10px auto 10px auto;
          color: #3c4858;
          text-align: center;
          min-height: 36px;
          padding-left: 5px;
          padding-right: 5px;
        }

        .uusimmat-textContainer .uusimmat-infoContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          height: auto;
          background-color: #ffccb9;
        }

        .uusimmat-textContainer .uusimmat-infoContainer .uusimmat-infoBox {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 50px;
          width: 33.3%;
          margin: 8px;
        }

        .uusimmat-textContainer .uusimmat-infoContainer .uusimmat-infoBox h4 {
          color: #3c4858;
          font-size: 11px;
          margin: 4px auto 0 auto;
        }

        .uusimmat-textContainer .uusimmat-infoContainer .uusimmat-infoBox p {
          font-size: 12px;
          color: #000;
          margin: 6px auto 0 auto;
        }
      }

      .last-item {
        justify-content: center;
        background-color: $primary;
        width: 300px;

        h6 {
          margin: auto;
          font-weight: 450;
          color: $secondary;
        }
      }

      .last-item:hover > h6 {
        font-weight: 650;
      }
    }
    a:hover {
      text-decoration: none;
    }

    a:hover > .menu-item {
      cursor: pointer;
      outline: 1px solid black;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Uusimmat {
    .UusimmatColText {
      width: 100%;

      p {
        margin: auto;
        margin-bottom: 16px;
      }
    }

    .scrollMenu {
      width: 100%;
    }
  }
}
