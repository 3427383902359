.scrollToTop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeInScrollToTop 0.3s;
  transition: opacity 0.4s;
  opacity: 0.8;
  padding: 12px;

  img {
    height: 16px;
    width: auto;
  }
}

.scrollToTop:hover {
  opacity: 1;
}

@keyframes fadeInScrollToTop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
