@import '../../variables';

.LoadingScreen {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;

    img {
      margin-bottom: 10px;
      box-shadow: $regularShadow;
    }

    div {
      display: flex;
      flex-direction: row;

      p {
        font-size: 20px;
        margin-top: 15px;
        margin-right: 15px;
        font-weight: 550;
      }
    }
  }
}
