@import '../../variables';

.Footer {
  display: flex;
  flex-direction: column;
  background-color: $primary;
  // height: 580px;

  // margin-top: 200px;

  .footerContainer {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $primary;
    width: 920px;
    // min-height: 535px;
    padding: 0;

    .row {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .row:first-of-type {
      width: 100%;
      // max-width: 840px;
      // min-height: 180px;
      padding: 0;
      margin: auto;

      div {
        display: flex;
        flex-direction: column;
        width: 180px;
        height: 180px;
        padding: 0;
        margin: 15px;
        background-color: #fff;
        box-shadow: $regularShadow;

        a {
          transition: transform 0.15s ease-in-out;
          margin: auto;
          img {
            margin: auto;
          }
        }

        img {
          margin: auto;
        }
      }

      div a:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }

    .row .col h4 {
      text-align: center;
      font-size: 40px;
    }

    .row .col {
      position: unset;

      .contactInfo {
        display: flex;
        flex-direction: column;
        padding-top: 12px;

        p {
          text-align: center;
          font-size: 15px;
          line-height: 14px;
        }

        a {
          text-align: center;
          font-size: 15px;
          line-height: 14px;
          margin-bottom: 16px;
          color: $secondary;
        }

        a:hover {
          color: rgb(38, 55, 118);
        }
      }
    }

    .row .col .socialLinks {
      display: flex;
      flex-direction: column;
      height: 90px;
      font-size: 15px;

      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 auto;
        width: 230px;

        img {
          width: 28px;
          margin: auto 20px auto 0;
        }

        a {
          padding: 0.5rem 0;
          color: #000;
          white-space: nowrap;
        }

        a:hover {
          color: rgb(38, 55, 118);
        }
      }
    }

    .row:last-of-type {
      p {
        margin: 0 auto 15px auto;
        font-size: 15px;
      }
    }

    #laatulkvLogo {
      margin: 0 auto;
      box-shadow: $regularShadow;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Footer {
    width: 100%;

    .footerContainer {
      width: 100%;
      max-width: unset;

      .row {
        display: flex;
        width: 100%;
      }

      #secondRow {
        margin: auto;
      }

      .row:last-of-type {
        margin: auto;
        p {
          padding: 10px 30px;
          text-align: center;
          margin: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .Footer {
    .footerContainer {
      #secondRow {
        flex-direction: column;
      }
    }
  }
}
