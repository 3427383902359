@import '../../../variables';

.Asiakaspalaute-bg {
  width: 100vw;
  min-height: 100vh;
  background-color: $primary;
}

.Asiakaspalautelomake {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding: 1rem;
  padding-top: 3rem;
  min-height: 100vh;
  background-color: #fff;
  width: 100%;
  max-width: 1024px;
  box-shadow: $regularShadow;

  h2 {
    margin: 1rem auto 0 auto;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 120px;
    margin: 0 auto;
  }

  #ohje-teksti {
    font-size: 16px;
    max-width: 640px;
    margin: 0 auto 1rem auto;
  }

  form {
    width: 100%;
    max-width: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    hr {
      width: 100%;
      margin: 2rem auto;
      border-top: 1px solid rgba(0, 0, 0, 0);
    }

    h6 {
      font-weight: 600;
      font-size: 16px;
      margin: 1rem auto;
    }

    textarea {
      width: 100%;
      max-width: 640px;
      border-radius: 8px;
      padding: 0.5rem;
    }

    ul {
      width: 360px;
      padding: 0;
    }

    ul li {
      list-style-type: none;
      margin: 2rem auto 0.5rem auto;
    }

    ul li:first-of-type {
      margin-top: 1rem;
    }

    select,
    input {
      padding: 0.5rem;
      margin-bottom: 1rem;
      border-radius: 8px;
      border-width: 1px;
    }

    input#message1 {
      width: 100%;
      max-width: 420px;
    }

    #dropdown2,
    #dropdown3,
    input#message3,
    input#message4 {
      width: 100%;
      max-width: 300px;
    }

    .valittaja-kuva {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin: 0 auto;
      box-shadow: $regularShadow;
      border: 2px solid $primary;
    }

    #radio-group label {
      margin: auto 1rem;

      input {
        margin-right: 0.25rem;
      }
    }

    label {
      margin-bottom: 0.5rem;
    }

    .validation-error {
      background-color: rgba(255, 0, 0, 0.605);
      border-radius: 5px;
      padding: 0.5rem 1rem;
      color: #fff;
      margin-top: 0.5rem;
    }

    a {
      color: rgb(79, 21, 194);
    }

    .btn {
      margin: 3rem auto 0.5rem auto;
      font-size: 16px;
      font-weight: 600;
      padding: 1rem 2rem;
      max-width: 240px;
      box-shadow: $regularShadow;

      .spinner-border {
        width: 21px;
        height: 21px;
        margin-left: 49.5px;
        margin-right: 49.5px;
      }
    }

    .btn:hover {
      background-color: #e2b09e;
    }

    .btn:focus {
      box-shadow: $regularShadow;
    }
  }
}
