@import '../../variables';

.OtaYhteytta {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 120px;

  h2.customSubTitle {
    margin-top: 64px !important;
  }

  p:first-of-type {
    margin-top: 24px;
  }

  form {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 50%;
    max-width: 500px;
    margin: auto;

    label {
      margin-bottom: 0;
    }

    input {
      margin-bottom: 15px;
    }

    .btn {
      margin-top: 15px;
      margin-bottom: 30px;
    }

    #error {
      background-color: rgba(255, 0, 0, 0.605);
      border-radius: 5px;
      padding: 3px;
      color: #fff;
    }
  }

  a {
    color: $primary;
  }
}

@media only screen and (max-width: 700px) {
  .OtaYhteytta {
    width: 90%;
    padding-top: 20vmin;

    label {
      font-size: 16px;
      padding-top: 15px;
    }

    form {
      width: 90%;

      input {
        padding: 10px 0px;
      }

      .btn {
        margin-top: 40px;
        padding: 16px 0;
      }
    }
  }
}
