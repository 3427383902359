@import '../../variables';

.EsittelyVideo {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 140px;

  .EsittelyVideoColText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    text-align: center;

    // p {
    //   margin: 1rem 20%;
    //   font-size: 14px;
    // }

    iframe {
      box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.5);
      margin: 2rem auto;
    }
  }

  .EsittelyVideoColImage {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    padding: 8rem 1rem;
    width: 20%;
    max-height: 420px;
    background-color: $primary;
    box-shadow: -10px 10px 0px 0px #d8d8d8;

    // img {
    //   display: block;
    //   width: 100%;
    //   height: auto;

    //   // object-fit: cover;
    // }

    h1 {
      color: #000;
      font-size: 64px;
      font-family: 'Playlist', Script;
      font-weight: 300;
      margin: 0 auto;
      white-space: nowrap;
    }

    h2 {
      color: #000;
      font-size: 18px;
      font-family: 'Bebas Neue';
      font-weight: 400;
      margin: 1rem auto;
      white-space: nowrap;

      letter-spacing: 8px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .EsittelyVideo {
    .EsittelyVideoColText {
      width: 100%;

      iframe {
        width: 90%;
      }
    }
  }
}
