@import '../../variables';

.Laatulkv {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  width: 100%;
  max-width: 1600px;

  .customSubTitle {
    margin-bottom: 16px !important;
  }

  .personContainer {
    width: 100%;
    max-width: 920px;
    margin: 25px auto;

    .personHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 30px auto;

      .headerSlot {
        display: flex;
        flex-direction: column;
        text-align: left;

        .customSubTitle {
          margin-left: 0;
        }
      }

      .headerSlot:first-of-type {
        width: -webkit-fill-available;
        width: -moz-available;
        justify-content: flex-end;

        h3 {
          font-size: 20px;
        }

        .henkiloYhteystiedot {
          display: flex;
          flex-direction: row;
          margin: 1rem auto 0 0;
          width: 100%;
          max-width: 400px;
          padding: 16px;
          background-color: $primary;
          font-size: 14px;

          a {
            color: #000;
          }

          div {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;

            img {
              width: 22px;
              height: 22px;
              margin-right: 15px;
            }
          }
        }
      }

      .headerSlot:last-of-type {
        width: 333px;
        padding-bottom: 10px;
        padding-right: 10px;
        img {
          width: 323px;
          height: 323px;
          box-shadow: 10px 10px 0px 0px $primary;
        }
      }
    }

    p {
      width: 100%;
      margin: auto;
      padding: 8px;
    }

    .personFeedbackTitle {
      font-size: 20px;
      padding: 8px;
      margin: 8px 0 0 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Laatulkv {
    padding-top: 20vmin;

    .personContainer {
      .personHeader {
        flex-direction: column-reverse;

        .headerSlot:first-of-type {
          width: 100%;
          justify-content: center;
          margin: auto;
          padding-top: 30px;
          text-align: center;

          .customSubTitle {
            margin-left: unset;
            margin: auto;
          }

          .henkiloYhteystiedot {
            margin-left: unset;
            margin: 1rem auto 0 auto;
          }

          h3 {
            font-size: 20px;
            padding: 0 30px;
          }
        }

        .headerSlot:last-of-type {
          margin: auto;
        }
      }

      p {
        padding: 8px 30px;
      }

      .personFeedbackTitle {
        font-size: 20px;
        padding: 8px 30px;
      }

      ul {
        padding-left: 50px;

        li > p {
          padding-left: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .Laatulkv {
    .personContainer {
      .personHeader {
        .headerSlot:first-of-type {
          .henkiloYhteystiedot {
            flex-direction: column;
            justify-content: center;
            width: 70%;
            padding: 8px;

            div {
              width: 100%;
              margin: 8px auto;
            }
          }
        }
        .headerSlot:last-of-type {
          img {
            width: 283px;
            height: 283px;
            margin: 0 auto;
            box-shadow: 10px 10px 0px 0px $primary;
          }
        }
      }
    }
  }
}
