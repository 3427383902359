@import '../../variables';

.Palvelut {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  width: 100%;
  max-width: 1600px;

  .palvelutIntro {
    width: 50%;
    margin: 0 auto 20px auto;
    text-align: center;
  }

  .palvelutContainer {
    display: flex;
    flex-direction: row;
    margin: 24px auto;
    width: 100%;

    .btn {
      width: 50%;
      border-radius: 5px;
      margin: auto 25px;
      margin-top: 0;
      padding: 0 30px;
      border: none;
      background-color: $primary;
      box-shadow: $regularShadow;
      transition: transform 0.15s ease-in-out;

      .customSubTitle {
        font-size: 40px;
      }
    }

    .btn:active {
      color: #000;
      background-color: $primary;
      border-color: unset;
      box-shadow: $regularShadow;
    }

    .btn:focus {
      box-shadow: $regularShadow !important;
    }

    .palvelutCol {
      display: flex;
      flex-direction: column;
      width: 50%;
      border-radius: 5px;
      margin: auto 25px;
      margin-top: 0;
      padding: 0 30px;
      background-color: $primary;
      box-shadow: $regularShadow;

      .customSubTitle {
        font-size: 40px;
      }

      h5 {
        font-weight: 600;
        margin-top: 15px;
      }

      p:last-of-type {
        margin-bottom: 30px;
      }
    }

    .palvelutCol:hover {
      cursor: pointer;
    }

    .btn:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }

  .valitysPalkkio {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    margin: auto;

    .customSubTitle {
      font-size: 40px;
    }

    img {
      width: 100%;
      max-width: 640px;
      height: auto;
      margin: auto;
      margin-top: 10px;
      box-shadow: $regularShadow;
    }
  }

  .muutPalvelut {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    margin: auto;
    margin-top: 40px;

    .customSubTitle {
      font-size: 40px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Palvelut {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-top: 20vmin;

    .valitysPalkkio {
      .customSubTitle {
        font-size: 30px;
      }
    }

    .palvelutContainer {
      display: flex;
      flex-direction: column;
      margin: auto;
      align-items: center;

      h3 {
        width: 100%;
      }

      .btn {
        margin-bottom: 30px;
        width: 100%;

        .customSubTitle {
          font-size: 30px;
        }
      }

      .palvelutCol {
        width: 100%;
        margin-bottom: 30px;
        padding: 0 20px;

        p {
          font-size: 12px;
        }

        h5 {
          font-size: 14px;
        }

        .customSubTitle {
          font-size: 30px;
        }
      }
    }
  }
}
