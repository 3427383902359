@import '../../variables';

$text: 2.2vmax;
$title: 2.8vmax;

.NavBarMobile {
  display: flex;
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 15vmin;
  box-shadow: $regularShadow;
  background-color: $primary;
  animation-name: fadeInDown;
  animation-duration: 1s;

  .customSubTitleNavBar {
    color: #000;
    font-size: 32px;
    font-family: 'Playlist';
    font-weight: 300;
    margin: auto;
    margin-left: 10px;
    white-space: nowrap;
  }

  a {
    margin: 0;
    padding: 0;
  }

  #toggleMenu {
    width: 15%;
  }

  #barsIcon {
    margin: auto;
    height: 100%;
    width: auto;
    margin-left: 0;
    height: 50%;
    max-width: 33px;
  }
}

.MenuMobile {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  width: 100%;
  margin-top: 15vmin;
  background-color: $primary;
  animation-name: fadeInDown;
  animation-duration: 0.5s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  a {
    width: 100%;
    margin: 8px auto;
    color: #000;
    font-weight: 650;
  }

  a:first-of-type {
    margin-top: 16px;
  }

  a:last-of-type {
    margin-bottom: 16px;
  }

  a:focus {
    outline: 0 !important;
  }
  a:active {
    outline: 0 !important;
  }

  .socialMediaMobile {
    display: flex;
    flex-direction: row;
    z-index: 1;
    width: 100%;

    button {
      width: 50%;
      padding-bottom: 5px;
      padding-top: 5px;
      box-shadow: $regularShadow;
    }

    #fb {
      background-color: #3b5998;
      border: none;
      margin: 5px 10px 20px 20px;

      img {
        height: 30px;
        width: 30px;
      }
    }

    #ig {
      img {
        height: 33px;
        width: 33px;
      }
      border: none;
      margin: 5px 20px 20px 10px;
      background: #f09433;
      background: -moz-linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
      background: -webkit-linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
      background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    }
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
