@import '../../variables';

.Info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  border-top: 2px solid $primary;
  border-bottom: 2px solid $primary;
  margin: 2rem auto;
  white-space: nowrap;

  p {
    max-width: 100%;
    white-space: normal;
  }

  p span {
    font-weight: 600;
    vertical-align: middle;
    font-size: 16px;
    margin: auto;
  }
}

// @media only screen and (max-width: 1024px) {
//   .Info {
//   }
// }
