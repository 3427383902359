@import '../../variables';

.VuokraAsuntoHakemusUusi {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 920px;
  padding: 0 10px;

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    h5 {
      width: 100%;
      font-size: 18px;
      font-weight: 650;
      padding: 0 10px;
      margin: 20px auto;
      text-align: center;
    }

    label {
      font-size: 14px;
    }

    .form-group {
      width: 50%;
      padding: 0 10px;
    }

    // hr {
    //   width: calc(100% - 20px);
    //   border-top: none;
    //   border-bottom: 1px solid rgb(199, 199, 199);
    //   margin: 0 0 16px 0;
    // }
  }
}

@media only screen and (max-width: 768px) {
  .VuokraAsuntoHakemusUusi {
    form {
      .form-group {
        width: 100%;
      }

      .vakuusRadioButtons {
        display: flex !important;
        flex-direction: column !important;
        .form-check {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  // .modal-dialog {
  //   margin: 1rem auto;
  // }
}
