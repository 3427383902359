@import '../../variables';

.Koti {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  width: 100%;
  background-color: #fff;

  #floorPlan {
    width: auto;
    height: auto;
    max-width: 360px;
    max-height: 360px;
  }

  .agent-image {
    position: fixed;
    bottom: 11px;
    left: 8%;
    display: flex;
    width: 100px;
    height: 100px;
    z-index: 999;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: $regularShadow;
    justify-content: center;
    overflow: hidden;

    img {
      height: 100%;
      width: auto;
    }
  }

  .realty-agent {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    z-index: 998;
    padding: 0 calc(8% + 100px);
    background-color: $primary;
    box-shadow: $regularShadow;

    .agent-name {
      width: 33.33%;
      background-color: $primary;
      z-index: 998;

      h6 {
        margin: 0 0 2px 0;
        font-weight: 650;
        font-size: 14px;
        white-space: nowrap;
      }

      p {
        margin: 0;
        font-size: 12px;
      }
    }

    .agent-contact {
      display: flex;
      flex-direction: row;
      width: 33.33%;
      background-color: $primary;
      z-index: 998;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      img:last-of-type {
        margin-left: 16px;
      }

      a {
        white-space: nowrap;
        color: #000;
        font-size: 13px;
      }

      div {
        white-space: nowrap;
        font-size: 13px;
      }
    }
  }

  .carousel {
    display: flex;
    margin: auto;
    margin-bottom: 16px;
    justify-content: center;
    height: auto;
    max-height: 652px;
    width: auto;
    max-width: 978px;

    a {
      display: flex !important;
    }

    .carousel-caption {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 0;
      margin: 0;
      right: 0;
      left: 0;
      bottom: 0;

      p {
        margin: auto;
        padding: 10px;
      }
    }

    .carousel-indicators {
      max-width: 100%;
      margin: 0;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 5px;
    }

    .carousel-item img {
      margin: auto;
      height: auto;
      max-height: 652px;
      width: auto;
      max-width: -webkit-fill-available;
      max-width: -moz-available;
    }

    #carouselImage {
      margin: auto;
      width: 100%;
      max-height: 800px;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      background-color: rgba(0, 0, 0, 1);
      border-radius: 50%;
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-color: rgba(0, 0, 0, 0.6);
      background-size: 50%;
    }
  }
}

.btn-laatulkv {
  background-color: $primary;
  color: #000;
}

.btn-laatulkv:focus {
  outline: none;
  box-shadow: none;
}

.btn-laatulkv:hover {
  background-color: #ebbbaa;
}

.single-realty {
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
}

.single-realty #myCarousel {
  width: 100%;
  max-width: 1200px;
  max-height: 800px;
  margin: 3rem auto;
}

.single-realty a p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  background-color: #ffccb9;
  padding: 1rem;
  border: 1px solid #555555;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  width: 220px;
  text-align: center;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
}

.single-realty a p:hover {
  color: #ffffff;
}

.single-realty .imageListElement {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 30px auto;

  img {
    max-width: 100%;
    max-height: 67vh;
    width: auto;
    margin: auto;
  }

  p {
    margin: 5px auto;
  }
}

.text-title-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.text-title-row h2 {
  color: #555555 !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  text-align: center !important;
}

.text-title-row h1 {
  font-size: 28px;
  text-align: center;
}

.text-info-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.info-box h4 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin: 1rem 4rem;
  text-align: center;
}

.info-box p {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin: 1rem auto;
  text-align: center;
  color: #3c4858;
  width: 100px;
}

.text-presentation {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  text-align: center;
  width: 90%;
  max-width: 1000px;
}

.text-presentation p {
  font-size: 18px;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

.text-presentation pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-break: normal;
  border: none !important;
  border-radius: none !important;
}

.text-information-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #cdcdcd;
  padding: 24px 12px;
}

.text-information-block h3 {
  font-size: 24px;
  text-align: center;
}

.text-information-block img {
  width: 50%;
  max-width: 560px;
  margin: 3rem auto;
}

.text-information-block p {
  font-size: 18px;
}

.text-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-width: 280px;
  max-width: 1000px;
  margin: auto;
  padding: 0 1rem;
}

.text-item h6 {
  width: 50%;
  font-size: 16px;
  margin: 4px auto auto auto;
  text-align: left;
  border-bottom: 1px dotted #cdcdcd;
}

.text-item p {
  width: 50%;
  text-align: left;
  color: #555555;
  margin: 8px auto 8px 4px;
}

.text-item .parkingInfo {
  width: 50%;
  p {
    width: 100%;
    text-align: left;
  }
}

.container-realty {
  .btn-laatulkv {
    background-color: $primary;
    color: #000;
    max-width: 300px;
    padding: 10px 15px;
    margin: auto;
  }

  .btn-laatulkv:focus {
    outline: none;
    box-shadow: none;
  }

  .btn-laatulkv:hover {
    background-color: #ebbbaa;
  }
}

.no-side-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.mobile-agent {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .Koti {
    .realty-agent {
      img {
        display: none;
      }

      .agent-contact {
        img {
          display: initial;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Koti {
    padding-top: 20vmin;

    .agent-image {
      display: none;
    }

    .realty-agent {
      display: none;
    }

    .mobile-agent {
      display: flex;
      background-color: #fff;

      .mobile-agent-image {
        display: flex;
        width: 60%;
        height: 60%;
        max-height: 280px;
        max-width: 280px;
        border-radius: 50%;
        border: 3px solid $primary;
        margin: 16px auto;
        overflow: hidden;
        justify-content: center;

        img {
          display: flex;
          height: 100%;
          max-height: 280px;
          width: auto;
          border-radius: 50%;
          margin: 0 auto;
        }
      }

      .mobile-agent-container {
        white-space: nowrap;
        margin: 5px auto;
        padding: 0;
        max-width: 320px;

        .mobile-agent-col-1 {
          white-space: normal;
          text-align: center;
        }

        .mobile-agent-col-2 {
          margin: 0 auto;
          max-height: 140px;
          width: 100%;
          background-color: $primary;

          .mobile-agent-col-3 {
            padding: 0 35px;

            a {
              color: #000;
              font-size: 14px;
            }

            p {
              display: inline-block;
              color: #000;
              font-size: 14px;
            }

            #mobile-agent-icon {
              width: 22px;
              height: 22px;
              margin: 15px 15px 15px 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .Koti {
    .container-realty {
      width: 100%;
    }

    .text-information-block {
      padding: 24px 0;
    }

    .text-info-row {
      flex-direction: column;
    }

    .text-item {
      flex-direction: column;
      padding: 0;
      margin: auto;

      h6 {
        font-size: 14px;
        text-align: center;
        border-bottom: none;
        margin: 20px auto 5px auto;
        width: 90%;
      }
      p {
        font-size: 14px;
        margin: auto;
        text-align: center;
        width: 90%;
      }

      .parkingInfo {
        width: 100%;
        p {
          text-align: center;
        }
      }
    }

    .carousel .carousel-item img {
      height: auto;
      max-height: none !important;
      width: 100%;
    }
  }
}
