@import '../../variables';

.Hinnasto {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 140px;

  .HinnastoColText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    text-align: center;

    p {
      margin: 1rem 20%;
      font-size: 14px;
    }

    img {
      width: 100%;
      max-width: 640px;
      height: auto;
      margin: auto;
      margin-top: 16px;
      box-shadow: $regularShadow;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Hinnasto {
    flex-direction: column;

    .HinnastoColText {
      width: 100%;

      p {
        margin: auto;
        padding: 0 30px;
      }

      img {
        width: 90%;
        margin-top: 24px;
      }
    }
  }
}
