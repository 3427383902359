@import '../../variables';

.Esittely {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 140px;
  margin-top: 100vh;

  .esittelyColText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    text-align: center;

    p {
      margin: 1rem 20%;
      font-size: 14px;
    }
  }

  .esittelyColImage {
    display: flex;
    align-items: center;
    width: 20%;

    img {
      width: 100%;
      max-height: 560px;
      box-shadow: -10px 10px 0px 0px $primary;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Esittely {
    flex-direction: column-reverse;
    margin-top: 0;

    .esittelyColText {
      width: 100%;

      p {
        padding: 10px 30px;
        margin: auto;
      }
    }

    .esittelyColImage {
      width: 100%;

      img {
        object-fit: contain;
        max-height: none;
        max-width: 60%;
        margin: auto;
        margin-bottom: 24px;
      }
    }
  }
}
