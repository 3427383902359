@import '../../variables';

.Piia {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 140px;

  .PiiaColText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    text-align: center;

    p {
      margin: 1rem 20%;
      font-size: 14px;
    }

    a {
      color: #000;
    }

    .henkiloYhteystiedot {
      display: flex;
      flex-direction: row;
      margin: 16px auto;
      width: 100%;
      max-width: 400px;
      padding: 16px;
      background-color: $primary;
      // box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
      font-size: 14px;

      div {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        img {
          width: 22px;
          height: 22px;
          margin-right: 15px;
        }
      }
    }
  }

  .PiiaColImage {
    display: flex;
    align-items: center;
    width: 20%;

    img {
      width: 100%;
      max-height: 420px;
      box-shadow: -10px 10px 0px 0px $primary;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Piia {
    flex-direction: column-reverse;

    .PiiaColText {
      width: 100%;
      p {
        margin: auto;
        padding: 10px 15px;
      }

      h5 {
        padding-left: 15px;
        padding-right: 15px;
      }

      .henkiloYhteystiedot {
        flex-direction: column;
        padding: 15px;
        max-width: 260px;
        justify-content: center;

        div {
          white-space: nowrap;
          margin: 5px auto;
        }
      }
    }

    .PiiaColImage {
      display: flex;
      align-items: center;
      width: 100%;

      img {
        object-fit: contain;
        max-height: none;
        max-width: 60%;
        margin: auto;
        margin-bottom: 24px;
      }
    }
  }
}
