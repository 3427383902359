@import '../../../variables';

.Asiakaspalaute-bg {
  width: 100vw;
  min-height: 100vh;
  background-color: $primary;
}

.Asiakaspalaute {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding: 1rem;
  padding-top: 3rem;
  min-height: 100vh;
  background-color: #fff;
  width: 100%;
  max-width: 1024px;
  box-shadow: $regularShadow;

  img {
    width: 100%;
    height: auto;
    max-width: 240px;
    margin: 1rem auto;
  }

  #ohje-teksti {
    font-size: 16px;
  }

  #palaute-painikkeet {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .btn {
      font-size: 16px;
      font-weight: 600;
      margin: auto 1rem;
      padding: 1rem 2rem;
      max-width: 240px;
      box-shadow: $regularShadow;
    }

    .btn:hover {
      background-color: #e2b09e;
    }
  }
}

@media only screen and (max-width: 578px) {
  .Asiakaspalaute {
    img {
      max-width: 180px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .Asiakaspalaute {
    #palaute-painikkeet {
      flex-direction: column;
      align-items: center;

      .btn {
        margin-bottom: 1rem;
      }
    }
  }
}
