$primary: #fecbb8;
$primary_hover: #f1c0ae;
$secondary: #212529;
$regularShadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
$shadow_light: 0px 0px 10px 0px rgba(0, 0, 0, 0.67);

// LAYOUT BREAKPOINTS
$mobile_mini: 540px;
$mobile_max: 828px;
$tablet_max: 1024px;
$layout: 1366px;