@import '../../variables';

.Vuokrahakemus {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  width: 100%;
  max-width: 1600px;

  h1.customSubTitle {
    margin-bottom: 16px !important;
    text-align: center;
  }
}

@media only screen and (max-width: 1024px) {
  .Vuokrahakemus {
    padding-top: 20vmin;
  }
}

@media only screen and (max-width: 500px) {
  // .Vuokrahakemus {
  // }
}
