@import '../../_variables.scss';

.accept-cookie-policy {
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  background: #FFF;
  box-shadow: $shadow_light;
  border-top-left-radius: 0px;
  z-index: 1001;

  span.cookie-info {
    color: #000;
    font-size: 14px;
    margin: 0 0 0 auto;
    padding-right: 1.5rem;
    width: 100%;
    max-width: 540px;

    a {
      margin: 0;
      text-decoration: underline;
      font-weight: 600;
      color: #007bff !important;

      &:hover {
      color: $primary !important;
      }
    }
  }

  button {
    width: fit-content;
    font-size: 14px;
    margin: auto 0;
    white-space: nowrap;

    &:last-of-type {
    margin-right: auto;
    }
  }

}

.cookie-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem auto 0;
  text-align: center;

  span.cs-text {
      margin: 0.5rem auto;
  }

  span.cs-text:nth-of-type(2) {
      color: $secondary;
  }
}

@media only screen and (min-width: $layout) {
  .accept-cookie-policy {
    width: 895px;
    left: auto;
    right: 0;
    border-top-left-radius: 5px;
  }
}

@media only screen and (max-width: $mobile_max) {
  .accept-cookie-policy {
    flex-direction: column;
    text-align: center;

    span.cookie-info {
      margin-left: auto;
      margin-right: auto;
    }

    button {
      margin: 0.5rem auto;
    }
  }
}

@media only screen and (max-width: $mobile_mini) {
  .accept-cookie-policy {
    padding: 1rem;

    span.cookie-info {
      font-size: 14px;
    }

    button {
      font-size: 14px;
    }
  }
}