@import '../../variables';

.FbFeed {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 140px;
  margin-top: 0;

  .FbFeedContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 80%;
    margin: auto 0 75px 0;
  }

  .FbFeedContainer > div span {
    box-shadow: $regularShadow;
  }

  .FbColText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    text-align: center;

    p {
      margin: 1rem 20%;
      font-size: 14px;
    }
  }

  .FbColPosts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 1140px;
    text-align: center;
    margin: auto auto 100px auto;
    flex-wrap: wrap;
  }

  .FbColPosts > div {
    margin: 15px;
    height: fit-content;
    box-shadow: $regularShadow;
  }
}

.scaleDown > div {
  transform: scale(0.7);
}

@media only screen and (max-width: 1024px) {
  .FbFeed {
    .FbColText {
      width: 100%;
    }

    .FbFeedContainer {
      width: 100%;
      text-align: center;
      margin: auto auto 100px auto;
    }
  }
}
