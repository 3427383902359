@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import './variables';

@font-face {
  font-family: 'Playlist';
  src: local('Playlist'), url('./fonts/Playlist_Script.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  .customSubTitle {
    color: #000;
    font-size: 50px;
    font-family: 'Playlist';
    font-weight: 300;
    margin: 1.5rem auto;
    white-space: nowrap;
  }

  .linkTitle {
    color: #000;
    max-width: fit-content;
    margin: auto;
  }

  .linkTitle:hover {
    cursor: pointer;
    text-decoration: none;
  }

  .linkTitle:hover > .customSubTitle {
    color: $primary;
  }

  .Landing .animatedLogo h1 {
    font-family: 'Playlist';
    font-weight: 300;
  }

  p {
    font-size: 14px;
  }

  .btn-laatulkv {
    background-color: $primary;
    color: #000;
  }

  .btn-laatulkv:hover {
    background-color: $primary_hover;
  }

  .btn-laatulkv:focus {
    outline: none;
    box-shadow: none;
  }
}

@media only screen and (max-width: 1024px) {
  .App {
    .customSubTitle {
      font-size: 46px;
      white-space: normal;
      padding: 0 15px;
    }
  }
}
