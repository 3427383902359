@import '../../variables';

.Navbar {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  z-index: 999;

  img {
    margin-left: 20px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
    transition: transform 0.15s ease-in-out;
  }

  img:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  .linkContainer {
    display: flex;
    height: 100%;
    padding-left: 200px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.500437675070028) 18%,
      rgba(0, 0, 0, 0.500437675070028) 100%
    );

    a {
      margin: auto 20px;
      text-decoration: none;
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 0px;
      white-space: nowrap;

      img {
        height: 33px;
        width: 33px;
        margin: auto;
        box-shadow: none;
        border: 2px solid rgba(0, 0, 0, 0);
        padding: 2px;
        border-radius: 50%;
      }
    }

    a:hover {
      color: $primary;
      cursor: pointer;

      img {
        transform: none;
        border: 2px solid $primary;
      }
    }

    a:last-of-type {
      margin-right: 40px;
    }

    .linkDecoration {
      font-weight: 800;
      color: $primary;
      margin: auto 20px;
    }
  }
}

@media only screen and (max-width: 1340px) {
  .Navbar {
    .linkContainer {
      padding-left: 100px;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 11%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
  }
}

@media only screen and (max-width: 1240px) {
  .Navbar {
    background-color: rgba(0, 0, 0, 0.5);
    .linkContainer {
      padding-left: 0px;
      background: transparent;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .Navbar .linkContainer a {
    margin: auto 13px;
  }
}
