@import '../../variables';

.Evastekaytanto {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 120px;

  p:first-of-type,
  p:last-of-type {
    margin-top: 24px;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    padding: 0 16px;
  }

  a {
    color: $primary;
  }

  button {
    width: fit-content;
    font-size: 14px;
    margin: auto 0;
    white-space: nowrap;
    background: $primary;

    &:hover,
    &:active,
    &:focus {
      background-color: $primary;
    }
  }
}

@media only screen and (max-width: 700px) {
  .Evastekaytanto {
    width: 90%;
    padding-top: 20vmin;

  }
}
