@import '../../variables';

.Yhteistyossa {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  width: 100%;
  background-color: #fff;

  p {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding: 0 30px;
  }

  p:first-of-type {
    margin-top: 24px;
  }

  p:last-of-type {
    margin-top: 30px;
  }

  .yhteistyossaLogoContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: calc(230px * 4 + 30px);
    margin: auto;
    padding: 15px;
    flex-wrap: wrap;
    justify-content: center;

    .yhteistyoLogo {
      display: flex;
      justify-content: center;
      padding: 0;
      margin: 15px;
      width: 200px;
      height: 200px;
      border: none;
      border: 1px solid #fff;
      background-color: #fff;
      box-shadow: $regularShadow;

      a {
        display: flex;
        width: auto;
        height: auto;
        max-width: 200px;
        max-height: 200px;
        margin: auto;
        transition: transform 0.15s ease-in-out;
        img {
          width: auto;
          height: auto;
          max-width: 180px;
          max-height: 180px;
          margin: auto;
        }
      }

      a:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Yhteistyossa {
    padding-top: 20vmin;
  }
}
