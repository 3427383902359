@import '../../variables';

.Kodit {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  width: 100%;
  background-color: #fff;
  // background-image: url('../../images/forest-background-bw.jpg');
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-attachment: fixed;
}

.realty {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1250px;
  height: 258px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  margin: 12px auto;
  box-sizing: border-box;
  border-bottom: 8px solid #ffccb9;
  transition: transform 0.1s linear 0s, box-shadow 0.1s ease-in-out 0s;
}

.realty img {
  min-width: 375px;
}

.realty .textContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  height: 250px;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
}

.realty .textContainer h3 {
  font-size: 17px !important;
  font-weight: 400;
  color: #555555;
  margin: 20px auto;
  margin-top: 0;
}

.realty .textContainer h2 {
  font-size: 20px;
  margin: 20px auto;
  margin-top: 0;
  color: #3c4858;
}

.realty .textContainer .infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 110px;
  margin-top: 8px;
}

.realty .textContainer .infoContainer .infoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffccb9;
  height: 110px;
  width: 110px;
  border-radius: 500px;
}

.realty .textContainer .infoContainer .infoBox h4 {
  color: #3c4858;
  font-size: 14px;
  margin: 0.25rem auto 0 auto;
}

.realty .textContainer .infoContainer .infoBox p {
  font-size: 16px;
  margin: 0.5rem auto 0 auto;
}

.realtyFilterContainer {
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  padding: 24px;
  width: 90%;
  max-width: 1300px;
  align-self: center;
  margin: 0 auto 24px auto;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);

  .realtyFilterBox {
    display: flex;
    flex-direction: column;
    width: 20%;
    max-width: 250px;
    justify-content: flex-start;
    text-align: left;

    .form-group {
      display: flex;
      flex-direction: column;
      margin: 0;

      .form-check {
        margin: 0 15px;

        .form-check-label {
          white-space: nowrap;
        }
      }
    }
  }

  h6 {
    font-weight: 650;
  }

  .btn {
    width: fit-content;
    margin: auto;
    background-color: $primary;
  }
}

.realty:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.8);
  transform: scale(1.005);
}

.container-realty {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1300px;
  margin: auto;
  padding: 24px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.9);
  // -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  // -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  // box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);

  .currentResult {
    font-size: 18px;
    font-weight: 600;
    margin: 6px auto 12px auto;
    width: 100%;
  }
}

.container-realty h5 {
  width: 100%;
  text-align: center;
  margin: 2rem auto;
}

.container-realty a {
  display: contents;
}

.container-realty a:hover {
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .Kodit {
    padding-top: 20vmin;
  }

  .realtyFilterContainer {
    flex-direction: column;
    margin: auto;
    padding: 10px;
    margin-bottom: 20px;

    .realtyFilterBox {
      justify-content: center;
      margin: 8px auto;
      width: unset;
      max-width: unset;
      text-align: center;
    }
  }

  .container-realty {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;
    justify-content: center;
  }

  .container-realty h5 {
    width: 100%;
    text-align: center;
  }

  .container-realty > .realty {
    flex: 0 50%;
  }

  .realty {
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    height: 420px;
    margin: 1rem;
  }

  .realty img {
    max-width: 300px;
    max-height: 200px;
    min-width: unset;
  }

  .realty .textContainer {
    width: 100%;
    height: 220px;
    padding: 15px 10px;
    justify-content: center;
  }

  .realty .textContainer h3 {
    font-size: 14px;
    margin: 0 auto 5px auto;
    text-align: center;
  }

  .realty .textContainer h2 {
    font-size: 18px;
    margin: 0 auto 20px auto;
    text-align: center;
    min-height: 42px;
  }

  .realty .textContainer .infoContainer {
    margin-top: 0px;
  }

  .realty .textContainer .infoContainer .infoBox {
    height: 84px;
    width: 84px;
  }

  .realty .textContainer .infoContainer .infoBox h4 {
    font-size: 12px;
  }

  .realty .textContainer .infoContainer .infoBox p {
    font-size: 13px;
    margin-top: 0px;
  }
}

// .realty {
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
//   height: 258px;
//   -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
//   -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
//   box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
//   background-color: #fff;
//   margin: 1rem auto;
//   box-sizing: border-box;
//   border-bottom: 8px solid #ffccb9;
//   transition: transform 0.1s linear 0s, box-shadow 0.1s ease-in-out 0s;
// }

// .realty img {
//   max-width: 375px;
// }

// .realty .textContainer {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   max-width: 1000px;
//   height: 250px;
//   padding: 20px;
//   box-sizing: border-box;
//   margin: auto;
// }

// .realty .textContainer h3 {
//   font-size: 17px;
//   font-weight: 400;
//   color: #555555;
//   margin: 20px auto;
//   margin-top: 0;
// }

// .realty .textContainer h2 {
//   font-size: 20px;
//   margin: 20px auto;
//   margin-top: 0;
// }

// .realty .textContainer .infoContainer {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   height: 110px;
//   margin-top: 8px;
// }

// .realty .textContainer .infoContainer .infoBox {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   background-color: #ffccb9;
//   height: 110px;
//   width: 110px;
//   border-radius: 500px;
// }

// .realty .textContainer .infoContainer .infoBox h4 {
//   font-size: 14px;
//   margin-bottom: 0;
// }

// .realty .textContainer .infoContainer .infoBox p {
//   font-size: 16px;
//   margin-top: 0.5rem;
// }

// .realty:hover {
//   cursor: pointer;
//   -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.8);
//   -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.8);
//   box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.8);
//   transform: scale(1.005);
// }

// .container1 {
//   display: flex;
//   flex-direction: column;
//   width: 95%;
//   min-height: 500px;
//   background-color: #f7f7f7;
//   margin: auto;
//   padding-left: 2rem;
//   padding-right: 2rem;
//   padding-top: 1rem;
//   padding-bottom: 1rem;
//   border-radius: 10px;
//   box-sizing: border-box;
// }

// @media only screen and (max-width: 1024px) {
//   .container1 {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     padding: 1rem;
//     justify-content: center;
//   }

//   .container1 > div {
//     flex: 0 50%;
//   }

//   .realty {
//     flex-direction: column;
//     max-width: 300px;
//     width: 100%;
//     height: 420px;
//     margin-left: 1rem;
//     margin-right: 1rem;
//   }

//   .realty img {
//     max-width: 300px;
//     max-height: 200px;
//   }

//   .realty .textContainer {
//     width: 100%;
//     height: 220px;
//     padding: 15px 10px;
//     justify-content: center;
//   }

//   .realty .textContainer h3 {
//     font-size: 14px;
//     margin: 15px auto;
//     margin-top: 0;
//   }

//   .realty .textContainer h2 {
//     font-size: 18px;
//     margin: 15px auto;
//     margin-top: 0;
//     min-height: 42px;
//   }

//   .realty .textContainer .infoContainer {
//     margin-top: 0px;
//   }

//   .realty .textContainer .infoContainer .infoBox {
//     height: 84px;
//     width: 84px;
//   }

//   .realty .textContainer .infoContainer .infoBox h4 {
//     font-size: 12px;
//   }

//   .realty .textContainer .infoContainer .infoBox p {
//     font-size: 13px;
//     margin-top: 5px;
//   }
// }

// /* navbar font 12px -> 16px */
// .navbar .navbar-nav > li > a {
//   padding-top: 15px;
//   padding-bottom: 15px;
//   border-radius: 3px;
//   color: inherit;
//   font-size: 16px;
//   font-weight: 400;
//   text-transform: uppercase;
// }

// /* alpha 0.5 -> 0.0 */
// .header-filter::before {
//    background-color: rgba(0, 0, 0, 0);
// }

// /* darker fonts on etusivu */
// .hestia-about {
//    background-attachment: fixed;
//    color: #3C4858;
//    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//    font-size: 20px;
//    font-weight: 300;
// }

// .hestia-about p {
//    background-attachment: fixed;
//    color: #3C4858;
//    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//    font-size: 20px;
//    font-weight: 300;
// }

// p {
//    color: #3C4858;
//    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//    font-weight: 300;
//    font-size: 20px;
// }

// body {
//    color: #3C4858;
//    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//    font-weight: 300;
//    font-size: 20px;
// }

// h6 {
//  font-weight: bold;
// }

// .mywrapper {
//  display:grid;
//  grid-template-columns: 30% 70%;
//  grid-gap: .3em;
//  font-size: 18px;
// }

// .mywrapperesittely {
//  display:grid;
//  grid-template-columns: 100%;
//  grid-gap: .4em;
// }

// .kumppanit {
//  display:grid;
//  grid-template-columns: 48% 48%;
//  grid-gap: 1em;
// }

// .esittely p {
//  font-size: 20px;
// }
// .esittely strong {
//  font-size: 20px;
// }

// .page-content-wrap p {
//  font-size: 20px;
// }

// .single-realty {
//    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
//    box-sizing: border-box;
// }

// .single-realty #myCarousel {
//  width: 100%;
//  max-width: 1200px;
//  max-height: 800px;
//  margin: 3rem auto;
// }

// #myCarousel img {
//  object-fit: contain;
//  max-height: 800px;
// }

// #myCarousel p {
//  color: #ffffff;
//  font-size: 16px;
//  font-weight: 400;
//  background-color: rgba(0,0,0,0.5);
//  border-radius: 5px;
//  width: max-content;
//  margin: auto;
//  padding: 1rem 1rem 3.5rem 1rem;
// }

// #myCarousel .carousel-indicators {
//  bottom: 25px;
// }

// .single-realty a p {
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  color: black;
//  background-color: #ffccb9;
//  padding: 1rem;
//  border: 1px solid #555555;
//  border-radius: 5px;
//  font-size: 16px;
//  font-weight: 400;
//  margin: auto;
//  width: 220px;
//  text-align: center;
//  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
//  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
//  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
// }

// .single-realty a p:hover {
//  color: #ffffff;
// }

// .text-title-row {
//    width: 100%;
//    display: flex;
//    flex-direction: column;
//    padding: 1rem;
// }

// .text-title-row h3 {
//    color: #555555;
//    font-weight: 400;
//    font-size: 20px;
//    text-align: center;
// }

// .text-title-row h2 {
//  font-size: 28px;
//  text-align: center;
// }

// .text-info-row {
//    width: 100%;
//    display: flex;
//    flex-direction: row;
//    justify-content: center;
// }

// .info-box h4 {
//  display: flex;
//  flex-direction: column;
//  font-size: 18px;
//  margin: 1rem 4rem;
//  text-align: center;
//  width: 100px;
// }

// .info-box p {
//    display: flex;
//    flex-direction: column;
//    font-size: 20px;
//    margin: 1rem auto;
//    text-align: center;
//    color: #3C4858;
//    width: 100px;
// }

// .text-presentation {
//    display: flex;
//    flex-direction: column;
//    margin: 1rem auto;
//    text-align: center;
//    width: 90%;
//    max-width: 1000px;
// }

// .text-presentation p {
//  font-size: 18px
// }

// .text-presentation pre {
//    white-space: pre-wrap;
//    white-space: -moz-pre-wrap;
//    white-space: -pre-wrap;
//    white-space: -o-pre-wrap;
//    word-break: normal;
//  background-color: #FFFFFF !important;
//  border: none !important;
//  border-radius: none !important;
// }

// .text-information-block {
//    display: flex;
//    flex-direction: column;
//    justify-content: center;
//    border-top: 1px solid #cdcdcd;
//    padding: 1rem;
//    padding-bottom: 22px;

// }

// .text-information-block h3 {
//  font-size: 24px;
//  text-align: center;
// }

// .text-information-block img {
//    width: 50%;
//    max-width: 560px;
//    margin: 3rem auto;
// }

// .text-information-block p {
//  font-size: 18px;
// }

// .text-item {
//    display: flex;
//    flex-direction: row;
//    justify-content: center;
//    width: 50%;
//    min-width: 280px;
//    max-width: 560px;
//    margin: auto;
//    padding: 0 1rem;
// }

// .text-item h6 {
//    width: 50%;
//    font-size: 16px;
//    margin: 4px auto auto auto;
//    text-align: left;
//    border-bottom: 1px dotted #cdcdcd;
// }

// .text-item p {
//    width: 50%;
//    text-align: left;
//    color: #555555;
//    margin: 8px auto 8px 4px;
// }
