@import '../../variables';

.Landing {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background-image: url('../../images/auto_bg_bw.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -50px;
  background-position-x: center;

  .animatedLogo {
    width: 800px;
    display: block;
    text-align: left;
    position: fixed;
    bottom: 40px;
    left: 160px;

    h1 {
      color: $primary;
      font-size: 140px;
      font-family: 'Playlist';
      font-weight: 300;
      white-space: nowrap;
      text-shadow: 5px 5px rgba(0, 0, 0, 0.66);
    }

    .typewriter h1 {
      opacity: 0;
      overflow: hidden;
      animation: typing 4s steps(480, end) forwards;
      animation-delay: 2s;
    }

    h2 {
      color: #fff;
      font-size: 53px;
      font-family: 'Bebas Neue';
      font-weight: 400;
      letter-spacing: 12px;
      text-shadow: 5px 5px rgba(0, 0, 0, 0.66);
    }

    .animate__animated.animate__zoomIn {
      --animate-duration: 1s;
    }
  }

  .animatedLogo2 {
    width: 450px;
    height: 247px;
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: center;
    position: fixed;
    bottom: 40px;
    right: 160px;

    img {
      width: 180px;
      height: 180px;
      margin: auto;
    }
  }
}

.animate__animated.animate__fadeInUp {
  --animate-duration: 2s;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }

  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1440px) {
  .Landing {
    background-position-y: -30px;
  }
}

@media screen and (max-width: 1400px) {
  .Landing {
    background-position-y: -15px;
  }
}

@media screen and (max-width: 1376px) {
  .Landing {
    background-position-y: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .Landing {
    background-position-x: -90px;
  }
}

@media screen and (max-width: 1150px) {
  .Landing {
    background-position-x: -120px;
  }
}

@media screen and (max-width: 1080px) {
  .Landing {
    background-position-x: -180px;
  }
}

@media screen and (max-width: 1024px) {
  .Landing {
    background-position-x: 0;
  }
}

// @media screen and (min-width: 2048px) {
//   .Landing {
//     background-size: cover;
//   }
// }
