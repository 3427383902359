@import '../../variables';

.LandingMobile {
  width: 100%;
  height: calc(100vh - 15vmin);
  margin-top: 15vmin;
  z-index: -1;
  background-color: #fff;
  // background-image: url('../../images/laatulkv-linna-tausta.jpeg');
  background-image: url('../../images/auto_bg_bw_cropped.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  text-align: center;

  .animatedLogo {
    width: 312px; //266px on tekstin leveys
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0 auto 0 10vmin;
    padding-top: 56vmin;

    h1 {
      display: flex;
      margin-right: 23px;
      width: 266px;
      color: $primary;
      font-size: 70px;
      font-family: 'Playlist';
      font-weight: 300;
      white-space: nowrap;
      text-shadow: 3px 3px rgba(0, 0, 0, 0.7);
      text-align: center;
    }

    .typewriter {
      h1 {
        opacity: 0;
        overflow: hidden;
        animation: typing 3s steps(480, end) forwards;
        animation-delay: 1s;
      }
    }

    h2 {
      color: #fff;
      font-size: 40px;
      font-family: 'Bebas Neue';
      font-weight: 400;
      letter-spacing: 12px;
      text-shadow: 3px 3px rgba(0, 0, 0, 0.7);
    }

    .animate__animated.animate__zoomIn {
      --animate-duration: 1s;
    }

    img {
      position: absolute;
      bottom: 35px;
      margin: auto;
      left: calc(100vw / 2 - 9px);
      display: flex;
      flex-direction: column;
      height: 40px;
      width: auto;
    }
  }
}

.animate__animated.animate__fadeInUp {
  --animate-duration: 2s;
}

.animate__animated.animate__bounce {
  --animate-duration: 2.5s;
  animation-iteration-count: infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }

  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 2048px) {
  .LandingMobile {
    background-size: cover;
  }
}

@media screen and (max-width: 800px) {
  .LandingMobile .animatedLogo {
    padding-top: 67vmin;
  }
}

@media screen and (max-width: 760px) {
  .LandingMobile .animatedLogo {
    padding-top: 73vmin;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 648px) {
  .LandingMobile {
    background-image: url('../../images/laatulkv-linna-tausta.jpeg');

    .animatedLogo {
      padding-top: 0vmin;
    }
  }
}
