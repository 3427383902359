@import '../../variables';

.Asetukset {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  width: 100%;
  max-width: 1600px;

  form {
    width: 100%;
    max-width: 700px;
    margin: auto;
    text-align: center;

    .btn {
      background-color: $primary;
      margin: auto;
    }
  }
}
